import { RightDrawerParentPaths } from "@sdge-utils/globalTypes";

export type DrawerRouterTypes = {
  parentPath: RightDrawerParentPaths;
  backArrow: boolean;
  prevPath: string;
  newPath: string;
  subPath: string;
};

export enum QuickActions {
  AGGREGATION = "aggregation",
}

export type DrawerStateTypes = {
  drawerOptions: {
    title: string;
    cardId: string;
    issueId: string;
    isOpen: boolean;
    numberOfPoles: string;
    manual: boolean;
  };
  interactionFormKey: string;
  notificationsData: {
    notificationPage: number;
    notificationPaginationLoading: boolean;
    notificationLoading: boolean;
    notifications: Array<any>;
    notificationsCount: number;
    lastPage: boolean;
  };
  interactionsData: {
    interactionsLoading: boolean;
    interactions: Array<any>;
  };
  programData: {
    programInfoLoading: boolean;
    rightSideBarUpdateLoading: boolean;
    isLoaded: boolean;
    programInfo: any;
    overlappingInfo: any[];
  };
  poleHistory: any[];
  selectedNotification: any;
  selectedInteraction: any;
  router: DrawerRouterTypes;
  quickActions: {
    [key in QuickActions]: {
      loading: boolean;
      shouldWait: boolean;
      interval: number;
    };
  };
};

export type setInteractionBodyTypes = {
  interactionType: string;
  emails?: string[];
  comment: string;
  event_owner: string;
  date: string;
  file: File;
};

export type DeleteInteractionTypes = {
  index: number;
  id: string;
};
