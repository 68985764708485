import yellowActiveSvg from "../../assets/yellowActiveSvg.svg";
import yellowMarker from "../../assets/yellowMarker.svg";
import redActiveSvg from "../../assets/redActiveSvg.svg";
import redMarker from "../../assets/redMarker.svg";
import blueActiveSvg from "../../assets/blueActiveSvg.svg";
import blueMarker from "../../assets/blueMarker.svg";
import purpleActiveSvg from "../../assets/purpleActiveSvg.svg";
import purpleMarker from "../../assets/purpleMarker.svg";
import greenActiveSvg from "../../assets/greenActiveSvg.svg";
import greenMarker from "../../assets/greenMarker.svg";
import orangeMarker from "../../assets/orangeMarker.svg";
import activeOrangeMarker from "../../assets/activeOrangeMarker.svg";
import grayMarker from "../../assets/greyMarker.svg";
import grayActiveMarker from "../../assets/activeGrayMarker.svg";
import darkGreenMarker from "../../assets/darkGreenMarker.svg";
import activeDarkGreenMarker from "../../assets/activeDarkgreenMarker.svg";
import pinkMarker from "../../assets/pinkMarker.svg";
import activePinkMarker from "../../assets/activePinkMarker.svg";
import darkBlueMarker from "../../assets/darkBlueMarker.svg";
import activeDarkBlueMarker from "../../assets/activeDarkBlueMarker.svg";

export const mapCenter = { lat: 32.57, lng: -116.98 };

export const containerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "10px",
};

export const API_KEY = "AIzaSyA0zsPaxFJBiLnZZA8daUWVPPsRfrBho14";

export const markersObject = [
  {
    variant: "replaced",
    markers: { activeMarker: activeOrangeMarker, marker: orangeMarker },
  },
  {
    variant: "critical",
    markers: { activeMarker: redActiveSvg, marker: redMarker },
  },
  {
    variant: "descoped",
    markers: { activeMarker: grayActiveMarker, marker: grayMarker },
  },
  {
    variant: "overlapping_programs",
    markers: { activeMarker: purpleActiveSvg, marker: purpleMarker },
  },
  {
    variant: "newEvent",
    markers: { activeMarker: greenActiveSvg, marker: greenMarker },
  },
  {
    variant: "complex",
    markers: { activeMarker: redActiveSvg, marker: redMarker },
  },
  {
    variant: "completed_by_other_programs",
    markers: { activeMarker: greenActiveSvg, marker: greenMarker },
  },
  {
    variant: "completed_by_diar",
    markers: { activeMarker: activeDarkGreenMarker, marker: darkGreenMarker },
  },
  {
    variant: "sensitive_customer",
    markers: { activeMarker: activePinkMarker, marker: pinkMarker },
  },
  {
    variant: "release_day_30",
    markers: { activeMarker: yellowActiveSvg, marker: yellowMarker },
  },
  {
    variant: "new_event",
    markers: { activeMarker: blueActiveSvg, marker: blueMarker },
  },
  {
    variant: "overLappingWithoutReply",
    markers: { activeMarker: activeDarkBlueMarker, marker: darkBlueMarker },
  },
];
