import { Box, Grid, IconButton } from "@mui/material";
import { Styled } from "./SapTileContent.styled";
import { CloseIcon, EditIcon } from "../../icons";
import React, { useEffect, useState } from "react";
import { TextInputWithController, DatePickerInput } from "@sdge-components";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { updateSap } from "@sdge-store/issueTracker/issueTracker.async";

import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import { updateConstructionSap } from "@sdge-store/construction/construction.async";
import { usePermission } from "@sdge-hooks";
import { checkSapTilePermissions } from "@sdge-utils/helpers/editHelpers/editHelpers";

const RowItem: React.FC<{
  title: string;
  content: string;
  name: string;
  date: boolean;
  constructionPage?: boolean;
  itemId: string;
}> = ({ title, content, name, date, constructionPage, itemId }) => {
  const [editable, setEditable] = useState(false);
  const { control, handleSubmit, reset, setValue } = useForm();
  const dispatch = useDispatch();

  const { getPermission } = usePermission();
  const { programInfo } = useAppSelector(
    (state) => state.drawerState.programData
  );
  const { activeIssueId, sapTileContent } = useAppSelector(
    (state) => state.issueTrackerSlice
  );

  const { sapTileContentConstruction, activeConstructionGroupName } =
    useAppSelector((state) => state.constructionSlice);
  const hasPermissions = checkSapTilePermissions(
    programInfo.ballInCourt,
    name,
    getPermission,
    constructionPage
  );

  const handleSetEdit = () => {
    reset();
    setEditable(true);
  };

  useEffect(() => {
    setEditable(false);
  }, [activeConstructionGroupName, activeIssueId]);

  const activeConstructionItem = sapTileContentConstruction.filter(
    (item: any) => item.id === itemId
  )[0];

  const handleFormSubmit = (values: any) => {
    constructionPage
      ? dispatch(
          updateConstructionSap({
            id: itemId,
            body: { ...activeConstructionItem, [name]: values[name] },
            groupName: activeConstructionGroupName,
          })
        )
      : dispatch(
          updateSap({
            id: activeIssueId,
            body: { ...sapTileContent[0], [name]: values[name] },
          })
        );
    setEditable(false);
  };

  return (
    <Box pb="11px" pt="11px">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container wrap="nowrap" justifyContent="space-between">
          <Box width="40%" mr="10px">
            <Styled.Title>{title}</Styled.Title>
          </Box>
          {editable && name !== "facilityId" ? (
            <>
              {date ? (
                <DatePickerInput
                  name={name as `${string}`}
                  control={control}
                  readOnly
                />
              ) : (
                <TextInputWithController
                  defaultValue={content}
                  name={name as `${string}`}
                  control={control}
                  multiline={true}
                />
              )}
            </>
          ) : (
            <Styled.Content noWrap={false}>{content}</Styled.Content>
          )}
          <>
            {programInfo.bmcdTier3 !== "Y" &&
            hasPermissions &&
            name !== "facilityId" ? (
              <>
                {editable ? (
                  <>
                    {date && (
                      <IconButton
                        onClick={() => {
                          setValue(name as `${string}`, "");
                        }}
                      >
                        <CloseIcon size={14} />
                      </IconButton>
                    )}
                    <Button
                      type="submit"
                      endIcon={<EditIcon size={14} />}
                    ></Button>
                  </>
                ) : (
                  <IconButton onClick={handleSetEdit}>
                    <EditIcon size={14} />
                  </IconButton>
                )}
              </>
            ) : (
              <Box></Box>
            )}
          </>
        </Grid>
      </form>
    </Box>
  );
};

export default RowItem;
