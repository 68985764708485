import { Styled } from "./QuickActionDrawer.styled";
import { CircularProgress } from "@material-ui/core";
import * as React from "react";
import { StandardButton } from "@sdge-components";
import { useDispatch, useSelector } from "react-redux";
import { startAggregation } from "@sdge-store/drawer/drawer.async";
import { selectRightDrawer } from "@sdge-store/drawer/drawer.selector";
import { normalizedSeconds } from "@sdge-utils/dateParser";

const QuickActionDrawer = () => {
  const dispatch = useDispatch();

  const onStartAggregation = () => {
    dispatch(startAggregation());
  };

  const {
    quickAction: { aggregation },
  } = useSelector(selectRightDrawer);

  const buttonTitle = aggregation.loading ? (
    <CircularProgress color="inherit" />
  ) : aggregation.shouldWait ? (
    <Styled.TimerWrapper>
      <p>Start Aggregation</p>
      <p>{normalizedSeconds(aggregation.interval)}</p>
    </Styled.TimerWrapper>
  ) : (
    <>Start Aggregation</>
  );

  return (
    <Styled.QuickActionWrapper>
      <StandardButton
        onClick={onStartAggregation}
        title={buttonTitle}
        disabled={aggregation.loading || aggregation.shouldWait}
      />
    </Styled.QuickActionWrapper>
  );
};

export default QuickActionDrawer;
