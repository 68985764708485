import { fetchOverlapsData } from "../overlaps/overlaps.async";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { queryInstance } from "../../../api/config";
import { RootState } from "../store";
import {
  DeleteInteractionTypes,
  QuickActions,
  setInteractionBodyTypes,
} from "./drawer.types";
import { errorHandler } from "../../../api/utils";
import { SnackbarDataTypes } from "@sdge-store/snackbar/snackbar.types";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";
import {
  handleChangeQuickActionInterval,
  handleNotificationLoading,
} from "@sdge-store/drawer/drawer.slice";
import { fetchConstructionData } from "@sdge-store/construction/construction.async";
import { fetchIssueData } from "@sdge-store/issueTracker/issueTracker.async";
import { fetchCustomersData } from "@sdge-store/customers/customers.async";

export const getNotifications: any = createAsyncThunk(
  "drawer/getNotifications",
  async (_, { dispatch, getState }) => {
    const state: any = getState();
    const page = state.drawerState.notificationsData.notificationPage;
    const loading =
      state.drawerState.notificationsData.notificationPaginationLoading;
    if (loading) {
      dispatch(handleNotificationLoading(false));
      try {
        const { data }: any = await queryInstance.get(
          `/notifications?page=${page}`
        );
        return data;
      } catch (e) {
        errorHandler(e, dispatch);
      }
    }
  }
);

export const getInteraction: any = createAsyncThunk(
  "drawer/getInteraction",
  async (_, { getState, dispatch }) => {
    try {
      const state: any = getState();
      const poleID = state.drawerState.drawerOptions.title;
      const { data }: { data: any[] } = await queryInstance.get(
        `/interactions?poleId=${poleID}&includeGroup=true`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getCustomersByGroup: any = createAsyncThunk(
  "drawer/getCustomersByGroup",
  async (payload: any, { dispatch }) => {
    try {
      const { data }: { data: any[] } = await queryInstance.get(
        `/issue-tracker/group-customers?groupName=${payload}`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const setInteraction: any = createAsyncThunk(
  "drawer/setInteraction",
  async (body: setInteractionBodyTypes, { getState, dispatch }) => {
    const state: RootState | any = getState();
    const poleId = state.drawerState.drawerOptions.cardId;
    const page = state.overlapsSlice.page;
    try {
      const formData = new FormData();
      formData.append("interactionType", body.interactionType);
      formData.append("date", body.date);
      formData.append("event_owner", body.event_owner);
      formData.append("comment", body.comment);
      formData.append("poleId", poleId);
      formData.append("attachment", body.file);
      body.emails && formData.append("emails", body.emails?.join(","));

      const { data }: any = await queryInstance.post("/interactions", formData);
      const snackbarData: SnackbarDataTypes = {
        isOpen: true,
        alertColor: "success",
        message: "interaction is added",
      };
      dispatch(handleChangeSnackbarState(snackbarData));
      dispatch(fetchOverlapsData(page));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateInteraction: any = createAsyncThunk(
  "drawer/updateInteraction",
  async (
    { body, id }: { body: setInteractionBodyTypes; id: string },
    { dispatch, getState }
  ) => {
    const state: RootState | any = getState();
    const poleId = state.drawerState.drawerOptions.cardId;
    try {
      const formData = new FormData();
      formData.append("interactionType", body.interactionType);
      formData.append("date", body.date);
      formData.append("event_owner", body.event_owner);
      formData.append("comment", body.comment);
      formData.append("poleId", poleId);
      formData.append("attachment", body.file);
      formData.append("attachmentAction", body.file ? "update" : "remove");
      body.emails && formData.append("emails", body.emails?.join(","));

      const { data } = await queryInstance.put(`/interactions/${id}`, formData);
      const snackbarData: SnackbarDataTypes = {
        isOpen: true,
        alertColor: "info",
        message: "interaction is updated",
      };
      dispatch(handleChangeSnackbarState(snackbarData));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteInteraction: any = createAsyncThunk(
  "drawer/deleteInteraction",
  async ({ index, id }: DeleteInteractionTypes, { dispatch }) => {
    try {
      await queryInstance.delete(`/interactions/${id}`);
      const snackbarData: SnackbarDataTypes = {
        isOpen: true,
        alertColor: "info",
        message: "interaction is deleted",
      };
      dispatch(handleChangeSnackbarState(snackbarData));
      return { index };
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const setAcknowledge: any = createAsyncThunk(
  "drawer/setAcknowledge",
  async (body: any, { getState, dispatch }) => {
    const state: any = getState();
    const notifications = state.drawerState.notificationsData.notifications;
    const notificationId =
      state.drawerState.selectedNotification?.notificationId ?? 0;
    const poleId = state.drawerState.drawerOptions.cardId;
    try {
      await queryInstance.put("/acknowledgement", {
        ...body,
        notificationId,
        poleId,
      });
      const errorData: SnackbarDataTypes = {
        isOpen: true,
        alertColor: "success",
        message: "Acknowledge added",
      };
      dispatch(handleChangeSnackbarState(errorData));

      return notifications.findIndex((item: any) => item.id === notificationId);
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getProgramInfo: any = createAsyncThunk(
  "drawer/getProgramInfo",
  async (_, { getState, dispatch }) => {
    try {
      const state: any = getState();
      const poleId = state.drawerState.drawerOptions.cardId;
      const { data } = await queryInstance.get(
        `overlapping_programs/byPole?poleId=${poleId}`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const setProgramInfo: any = createAsyncThunk(
  "drawer/setProgramInfo",
  async ({ data, issueId }: any, { getState, dispatch }) => {
    const state: any = getState();
    const poleId = state.drawerState.drawerOptions.cardId;
    try {
      await queryInstance.post("/overlapping_program", {
        poleId,
        issueId,
        ...data,
      });

      const errorData: SnackbarDataTypes = {
        isOpen: true,
        alertColor: "success",
        message: "info is changed",
      };
      dispatch(handleChangeSnackbarState(errorData));
      dispatch(getProgramInfo());
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const setProgramInfoIssueTracker: any = createAsyncThunk(
  "drawer/setProgramInfoIssueTracker",
  async (cellInfo: any, { getState, dispatch }) => {
    const state: any = getState();
    const issueId = state.drawerState.drawerOptions.issueId;
    const cellInfoCopy = { ...cellInfo };
    delete cellInfoCopy.propagateFacilityChanges;
    const body = {
      propagateFacilityChanges: cellInfo.propagateFacilityChanges
        ? cellInfo.propagateFacilityChanges
        : false,
      updateIssueDto: cellInfoCopy,
    };
    try {
      await queryInstance.put(`/issue-tracker/${issueId}`, body);
      const errorData: SnackbarDataTypes = {
        isOpen: true,
        alertColor: "success",
        message: "info is changed",
      };
      const { sortingRules, filter } = state.issueTrackerSlice;
      dispatch(fetchIssueData([0, sortingRules, filter, true]));
      dispatch(getIssueInfo());
      dispatch(handleChangeSnackbarState(errorData));
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

/////////// CUSTOMER ////////////////////////

export const setAssetsInfo: any = createAsyncThunk(
  "drawer/setAssetsInfo",
  async (cellInfo: any, { getState, dispatch }) => {
    const state: any = getState();
    const poleId = state.drawerState.drawerOptions.cardId;
    const body = {
      circuit: cellInfo.circuit,
      descope: cellInfo.descope,
      descopeNotes: cellInfo.descopeNotes,
      deprioritizeNotes: cellInfo.deprioritizeNotes,
      hftdTier: cellInfo.hftdTier,
      mission: cellInfo.mission,
      tlid: cellInfo.tlId,
    };
    const { sortingRules, filter } = state.customersSlice;
    try {
      const { data } = await queryInstance.put(`/assets/${poleId}`, body);
      const errorData: SnackbarDataTypes = {
        isOpen: true,
        alertColor: "success",
        message: "info is changed",
      };
      dispatch(fetchCustomersData([0, sortingRules, filter, true]));
      dispatch(getAssetsInfo());
      dispatch(handleChangeSnackbarState(errorData));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getAssetsInfo: any = createAsyncThunk(
  "drawer/getAssetsInfo",
  async (_, { getState, dispatch }) => {
    try {
      const state: any = getState();
      const facilityId = state.drawerState.drawerOptions.cardId;
      // const { data }: any = await queryInstance.get(`assets/${facilityId}`);
      const { data }: any = await queryInstance.post(`/assets?page=0`, [
        {
          fieldName: "pole_id",
          negate: false,
          operation: "equals",
          relation: "AND",
          value: facilityId,
        },
      ]);

      return data?.content[0];
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

/////////////// ISSUE TRACKER ////////////////////////////

export const getIssueInfo: any = createAsyncThunk(
  "drawer/getIssueInfo",
  async (_, { getState, dispatch }) => {
    try {
      const state: any = getState();
      const issueId = state.drawerState.drawerOptions.issueId;
      if (issueId) {
        // const { data } = await queryInstance.get(`/issue-tracker/${issueId}`);
        const { data } = await queryInstance.post(`/issue-tracker?page=0`, [
          {
            fieldName: "issue_id",
            negate: false,
            operation: "equals",
            relation: "AND",
            value: issueId,
          },
        ]);
        return data.content[0];
      }
      return null;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

////////////////// HISTORY ////////////////////////////////

export const getPoleHistory: any = createAsyncThunk(
  "drawer/getPoleHistory",
  async (_, { getState, dispatch }) => {
    try {
      const state: any = getState();
      const issueId = state.drawerState.drawerOptions.issueId;
      const cardId = state.drawerState.drawerOptions.cardId;
      const { data } = await queryInstance.get(
        `/history?id=${issueId || cardId}`
      );

      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

/////////////// CONSTRUCTION ////////////////////////////

export const getConstructionInfo: any = createAsyncThunk(
  "drawer/getConstructionInfo",
  async (_, { getState, dispatch }) => {
    try {
      const state: any = getState();
      const groupName = state.constructionSlice.activeConstructionGroupName;
      // const { data }: any = await queryInstance.get(
      //   `/construction/info?groupName=${groupName}`
      // );
      const { data } = await queryInstance.post(`/construction?page=0`, [
        {
          fieldName: "group_name",
          negate: false,
          operation: "equals",
          relation: "AND",
          value: groupName,
        },
      ]);

      return data?.content[0];
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const setProgramInfoConstruction: any = createAsyncThunk(
  "drawer/setConstructionInfo",
  async (
    {
      groupName,
      ballInCourt,
      midpoint,
      overhead,
      underground,
      jucfRequired,
      pidsUpload,
      actualIfc,
      issueStatus,
      designCompletedAccepted,
      onHoldStatus,
      onHoldStatusComment,
      projectNumber,
      projectedIfc,
      jobNumber,
      forecastedIfc,
    }: any,
    { getState, dispatch }
  ) => {
    try {
      const body = {
        projectedIfc,
        jobNumber,
        projectNumber,
        ballInCourt,
        midpoint,
        overhead,
        underground,
        jucfRequired,
        pidsUpload,
        actualIfc,
        issueStatus,
        designCompletedAccepted,
        onHoldStatus,
        onHoldStatusComment,
        forecastedIfc,
      };
      await queryInstance.put(
        `/construction/info?groupName=${groupName}`,
        body
      );
      const errorData: SnackbarDataTypes = {
        isOpen: true,
        alertColor: "success",
        message: "info is changed",
      };
      const state: any = getState();
      const { sortingRules, filter } = state.constructionSlice;
      dispatch(fetchConstructionData([0, sortingRules, filter, true]));
      dispatch(getConstructionInfo());
      dispatch(handleChangeSnackbarState(errorData));
    } catch (error) {
      errorHandler(error, dispatch);
    }
  }
);

/////////////////// Quick Action  /////////////////

export const startAggregation: any = createAsyncThunk(
  "drawer/startAggregation",
  async (_, { dispatch }) => {
    try {
      const getInterval = (intervalProps: number) => {
        let tempData = intervalProps;
        const interval = setInterval(() => {
          --tempData;
          dispatch(
            handleChangeQuickActionInterval({
              intervalKey: QuickActions.AGGREGATION,
              interval: tempData,
            })
          );
          if (tempData <= 0) {
            clearInterval(interval);
          }
        }, 1000);
      };
      const { data } = await queryInstance.get("/aggregationStart");
      if (typeof data === "number") {
        getInterval(data);
      } else {
        getInterval(600);
      }
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);
