import React, { useEffect, useState } from "react";
import { Typography, Divider, Box, Grid } from "@material-ui/core";
import { Menu } from "react-feather";
import { Styled } from "./MainLayout.styled";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ThemeTypes } from "@sdge-store/theme/theme.types";
import {
  DrawerUserInfo,
  TabControls,
  BellButton,
  Button,
  QuickActionButton,
} from "../../components";
import {
  dummyUser,
  pageTitles,
  quickActionPermissionList,
  tabs,
} from "./MainLayout.constants";
import { useHistory, useLocation } from "react-router";
import Drawer from "./Drawer";
import {
  handleChangeDrawerOptions,
  handleRouterChange,
} from "@sdge-store/drawer/drawer.slice";
import { useAuth, usePermission } from "@sdge-hooks";
import { Redirect } from "react-router-dom";
import { paths } from "../../utils/routes";
import { getUserInfo } from "@sdge-store/user/user.async";
import { AddIcon } from "../../icons";
import { CreateIssueModal } from "@sdge-pages/IssueTracker";
import {
  RightDrawerParentPaths,
  RightDrawerPaths,
} from "@sdge-utils/globalTypes";
import { getUniqueValues } from "@sdge-store/autoComplete/autoComplete.async";
import SessionTimeout from "@sdge-components/SessionTimeout";
import { getNotifications } from "@sdge-store/drawer/drawer.async";

const MainLayout: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const { location } = useHistory();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(true);
  const { pathname } = useLocation();
  const issueTracker = pathname.split("/").includes("issue_tracker");
  const { theme } = useAppSelector((state) => state.appTheme);
  const isDark = Boolean(theme === ThemeTypes.DARK);
  const { notificationsCount, notifications } = useAppSelector(
    (state) => state.drawerState.notificationsData
  );
  const { src } = dummyUser;

  const { name, surname, email } = useAppSelector(
    (state) => state.userSlice.info
  );
  const { writePermissions } = useAppSelector((state) => state.userSlice.info);

  const { getPermission } = usePermission();
  const hasPermissions = getPermission("createIssue");

  useEffect(() => {
    if (isAuthenticated) dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    dispatch(getUniqueValues());
  }, []);
  useEffect(() => {
    if (!notifications.length) {
      dispatch(getNotifications());
    }
  }, []);
  if (!isAuthenticated) {
    return <Redirect to={`/${paths.login}`} />;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlerBell = () => {
    const routerOptions = {
      parentPath: RightDrawerParentPaths.NOTIFICATION,
      subPath: "allNotifications",
    };

    dispatch(handleRouterChange(routerOptions));
    dispatch(handleChangeDrawerOptions({ isOpen: true }));
  };

  const handleQuickAction = () => {
    const routerOptions = {
      parentPath: RightDrawerParentPaths.QUICK_ACTION,
      subPath: "allNotifications",
      newPath: RightDrawerPaths.QUICK_ACTION,
    };
    const drawerOptions = {
      title: "DB control panel",
    };
    dispatch(handleChangeDrawerOptions(drawerOptions));
    dispatch(handleRouterChange(routerOptions));
    dispatch(handleChangeDrawerOptions({ isOpen: true }));
  };

  const quickActionPermission = quickActionPermissionList.some((item) =>
    getPermission(item)
  );

  const titleInfo = Object.keys(pageTitles).filter((item) =>
    location.pathname.includes(item)
  );

  const pageTittle = pageTitles[titleInfo[0] as keyof typeof pageTitles];
  const tabsOption = tabs[titleInfo[0] as keyof typeof tabs];

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <Styled.LayoutWrapper>
      <SessionTimeout />
      <CreateIssueModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <Styled.AppBarWrapper elevation={0} open={open}>
        <Styled.LayoutToolbar variant="dense">
          <Styled.LayoutToolbarLeft>
            {!open && (
              <Styled.MenuButton
                onClick={handleDrawerOpen}
                color="inherit"
                aria-label="open drawer"
                edge="start"
              >
                <Menu />
              </Styled.MenuButton>
            )}
            <Typography>{pageTittle}</Typography>
          </Styled.LayoutToolbarLeft>
          <Styled.TopBarActionWrapper>
            {issueTracker && hasPermissions && (
              <Box pr="50px">
                <Button
                  icon={AddIcon}
                  title="Create Issue"
                  onClick={handleOpen}
                />
              </Box>
            )}
            <TabControls tabs={tabsOption} />
            {quickActionPermission && (
              <QuickActionButton onClick={handleQuickAction} />
            )}
            <BellButton count={notificationsCount} onClick={handlerBell} />
          </Styled.TopBarActionWrapper>
        </Styled.LayoutToolbar>
        <Divider />
      </Styled.AppBarWrapper>
      <Styled.MainDrawer variant="persistent" open={open}>
        <Drawer handleDrawerClose={handleDrawerClose} isDark={isDark} />
        <DrawerUserInfo
          fullName={`${name} ${surname}`}
          email={email}
          src={src}
        />
      </Styled.MainDrawer>
      <Styled.Page open={open}>
        <Styled.OverLay />
        {writePermissions.length ? (
          <> {children}</>
        ) : (
          <Grid container justifyContent="center" alignItems="center">
            <Styled.InfoBox>
              You have no Security Privileges. Please contact your system
              Administrator.
            </Styled.InfoBox>
          </Grid>
        )}
      </Styled.Page>
    </Styled.LayoutWrapper>
  );
};

export default MainLayout;
